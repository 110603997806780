export const resume = {
  header: "My Resume...kinda",
  content: [
    {
      id: "1321t3t1",
      name: "Education",
      sections: [
        {
          id: "12t1g432g",
          title: "MS Computer Engineering",
          subtitle: "University of New Mexico",
          subsubtitle: "Jan 2015 - July 2017",
          description: {
            title: "Thesis",
            content:
              "Incorporating Census Data into a Geospatial Student Database",
            url: "",
          },
        },
        {
          id: "g53h3h53",
          title: "BS Computer Engineering",
          subtitle: "Chungnam National University",
          subsubtitle: "Mar 2011 - Feb 2015",
        },
      ],
    },
    {
      id: "f24f24g24",
      name: "Skills",
      sections: [
        {
          id: "yhyrdnf",
          title: "Web and Mobile Development",
          list: [
            "React & Redux, Ruby on Rails, React Native",
            "Typescript, Javascript, HTML5, CSS3",
            "RESTful APIs, GraphQL",
            "Git, Github, Gitlab",
          ],
        },
        {
          id: "g54g45467",
          title: "Programming Languages",
          list: ["Javascript, Python, C#, Ruby, Java"],
        },
        {
          id: "grbwrnwr",
          title: "Continuous Integration / Deployment",
          list: ["Circle CI, Netlify, Heroku, Github Workflow"],
        },
        {
          id: "hrehrtw",
          title: "Database Management",
          list: ["MongoDB, PostgreSQL, Oracle, MySQL, Tableau"],
        },
      ],
    },
    {
      id: "2g4253h",
      name: "Experience",
      sections: [
        {
          id: "h46jk8",
          title: "Graduate Assistant (Software Developer)",
          subtitle:
            "University of New Mexico - Institute of Design and Innovation",
          subsubtitle: "Feb 2015 - Dec 2016",
          list: [
            "Worked with team to develop full-stack web applications using Ruby on Rails",
            "Integrated RESTful APIs and third-party software into existing applications",
            "Created and maintained database tables in the student datamart",
            "Provided technical documentation for running ETL processes for the datamart",
          ],
        },
        {
          id: "fghfnfgnf",
          title: "Data Analyst / Engineer (Institutional Researcher)",
          subtitle:
            "University of New Mexico - Office of Institutional Analytics",
          subsubtitle: "Feb 2017 - Apr 2021",
          list: [
            "Developed programs and scripts with R, Python and SQL to generate reports",
            "Implemented complex SQL queries to access and summarise student information",
            "Designed and created dynamic dashboards for internal, state and federal reports",
            "Documented technical processes in confluence and managed version control with GitLab",
            "Configured and documented coding environments including database access, environment variables and onboarding processes used by the team",
          ],
        },
      ],
    },
    {
      id: "g35g",
      name: "Languages",
      sections: [
        {
          id: "gg45h45",
          title: "English",
          subtitle: "Fluent",
        },
        {
          id: "4g24g4g",
          title: "Korean",
          subtitle: "Professional Working Proficiency",
        },
        {
          id: "h64h647",
          title: "French",
          subtitle: "Conversational",
        },
        {
          id: "52g5g4",
          title: "Japanese",
          subtitle: "Conversational",
        },
      ],
    },
  ],
  nextPageLink: {
    label: "Check out some of the projects I'm working on",
    path: "/projects",
  },
};
