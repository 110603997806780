import styled from "styled-components";
import { ProjectDescription } from "../components/projects/ProjectDescription";
import { projects } from "../mockdata/projects";
import { edmkaColors } from "../ui/colors";

const Projects = () => {
  const { header, content } = projects;
  return (
    <Container id="projects">
      <Header>{header}</Header>
      <Details>
        {content.map((contentItem) => (
          <ProjectDescription
            key={`projects-${contentItem.id}`}
            title={contentItem.name}
            description={contentItem.description}
            stack={contentItem.stack}
            imageLink={contentItem.imageLink ? contentItem.imageLink : ""}
            projectLink={contentItem.projectLink ? contentItem.projectLink : ""}
            github={contentItem.github ? contentItem.github : ""}
          />
        ))}
      </Details>
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  gap: 1em;
  justify-items: center;
  align-items: flex-start;
  padding-block-start: min(35%, 5em);
  min-height: min(90vh, 1000px);
  color: ${edmkaColors.lightGreen()};
`;

const Header = styled.header`
  font-size: 1.3rem;
  font-weight: 700;
  filter: drop-shadow(0.2em 0.2em 0.1em ${edmkaColors.darkBrown()});
`;

const Details = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
  max-width: max(20em, 80%);
`;

export default Projects;
