import styled from "styled-components";
import { edmkaColors } from "./colors";

interface NextPageLinkProps {
  label: string;
  path: string;
}

export const NextPageLink = ({ label, path }: NextPageLinkProps) => {
  return <Container href={`#${path}`}>{label}</Container>;
};

const Container = styled.a`
  text-decoration: none;
  color: ${edmkaColors.green()};
  font-size: clamp(0.5rem, 1rem, 1.5rem);
`;
