import styled from "styled-components";
import { about } from "../mockdata/about";

import { About as AboutTypes } from "../types/About";
import { edmkaColors } from "../ui/colors";

const About = () => {
  const { header, content }: AboutTypes = about;

  return (
    <Container id="about">
      <Header>{header}</Header>
      <Content>
        {content.map((paragraph, idx) => (
          <Paragraph key={`about-paragraph-${idx}`}>{paragraph}</Paragraph>
        ))}
      </Content>
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  gap: 1em;
  align-content: end;
  justify-items: center;
  min-height: min(90vh, 1000px);
  padding-block-start: min(35%, 5em);
  color: ${edmkaColors.lightGreen()};
`;

const Header = styled.header`
  font-size: 1.3rem;
  font-weight: 700;
  filter: drop-shadow(0.2em 0.2em 0.1em ${edmkaColors.darkBrown()});
`;

const Content = styled.div`
  display: grid;
  grid-gap: 1.5em;
  border-radius: 1em;
  max-width: max(16em, 60%);
  overflow-y: auto;
  padding: min(10%, 2em);
  line-height: 150%;
  background-color: ${edmkaColors.black(0.5)};
  border: 0.1em solid ${edmkaColors.lightGreen(0.4)};
`;

const Paragraph = styled.div``;

export default About;
