import styled from "styled-components";
import { contact } from "../mockdata/contact";
import { edmkaColors } from "../styles/SharedStyles";
import { Contact as ContactTypes } from "../types/Contact";

const Contact = () => {
  const { header, content }: ContactTypes = contact;

  return (
    <Container id="contact">
      <Header>{header}</Header>
      <Socials>
        {content.map((contactItem) => (
          <SocialsItem key={contactItem.id}>
            <SocialsName>{contactItem.name}</SocialsName>
            {contactItem.type === "email" ? (
              <SocialsLink href="mailto:eddy.devv@gmail.com">
                <EmailButton>Email</EmailButton>
              </SocialsLink>
            ) : (
              <SocialsLink
                href={contactItem.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {contactItem.url}
              </SocialsLink>
            )}
          </SocialsItem>
        ))}
      </Socials>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  gap: 1em;
  justify-items: center;
  align-content: center;
  padding-block: min(35%, 5em);
  color: ${edmkaColors.lightGreen()};
  padding: min(10%, 2em);
  min-height: min(90vh, 1000px);
`;

const Header = styled.header`
  font-size: 1.3rem;
  font-weight: 700;
  filter: drop-shadow(0.2em 0.2em 0.1em ${edmkaColors.darkBrown()});
`;
const Socials = styled.div`
  display: grid;
  grid-gap: 1.5em;
  border-radius: 1em;
  min-width: max(16em, 60%);
  max-height: min(30em, 80%);
  overflow-y: auto;
  padding: min(10%, 2em);
  line-height: 150%;
  background-color: ${edmkaColors.black(0.5)};
  border: 0.1em solid ${edmkaColors.lightGreen(0.4)};
`;
const SocialsItem = styled.div`
  display: flex;
  gap: 1em;
  justify-content: space-between;
  font-size: 1rem;
  overflow-x: auto;
`;

const SocialsName = styled.span`
  color: ${edmkaColors.green()};
`;

const SocialsLink = styled.a`
  color: ${edmkaColors.lightGreen()};
  text-decoration: none;
  font-size: 0.8rem;
  :hover {
    color: ${edmkaColors.pinkish()};
  }
  :visited: {
    color: ${edmkaColors.lightGreen()};
  }
`;

const EmailButton = styled.button`
  padding: 0.4rem 1rem;
  color: ${edmkaColors.pinkish()};
  background-color: ${edmkaColors.green(0.1)};
  border: 1px solid ${edmkaColors.pinkish()};
  border-radius: 0.4rem;
`;

export default Contact;
