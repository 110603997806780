export const projects = {
  header: "Interesting stuff I'm building...",
  content: [
    {
      id: "h46b46",
      name: "Edmkadex",
      description: "",
      github: "https://github.com/eddybanks/pokedex-test",
      stack: ["Axios", "React", "SWR", "Styled-components", "TypeScript"],
      imageLink:
        "https://res.cloudinary.com/edmka/image/upload/v1642549786/edmka/edmkadex-min.png",
      projectLink: "https://edmkadex.netlify.app/",
    },
    {
      id: "42g64j2",
      name: "Skywatcher",
      description:
        "I built this app with a few friends from the Chingu.io program.\nI worked on the styling and custom hooks to help with fetching data from the API and formatting our datetime objects.\nThe data was pulled from the OpenWeather API.",
      github: "https://github.com/chingu-voyages/v29-geckos-team-03",
      stack: ["Axios", "OpenWeather API", "React", "React-bootstrap"],
      imageLink:
        "https://res.cloudinary.com/edmka/image/upload/v1642549671/edmka/skywatcher-min.png",
      projectLink: "https://skywatcher.netlify.app/",
    },
    {
      id: "g4g234g24t",
      name: "Crwn Clothing",
      description: "",
      github: "https://github.com/eddybanks/crwn-clothing",
      stack: ["Axios", "React", "Redux", "Firebase"],
      imageLink:
        "https://res.cloudinary.com/edmka/image/upload/v1642549787/edmka/crwneddy-min.png",
      projectLink: "https://crwneddy.herokuapp.com/",
    },
    {
      id: "k757k57",
      name: "School Admin - Web & Mobile",
      description: "",
      github: "https://github.com/Paper-Huts/school-admin",
      stack: [
        "Axios",
        "React",
        "React-bootstrap",
        "React-native",
        "Redux-toolkit",
        "SWR",
        "TypeScript",
      ],
      imageLink:
        "https://res.cloudinary.com/edmka/image/upload/v1642549786/edmka/schooladmin-min.png",
      projectLink: "https://paperhuts.com/school-admin/#/",
    },
  ],
  nextPageLink: {
    label: "Wanna hit me up with some job offers or other reason?",
    path: "/contact",
  },
};
