export const contact = {
  header: "Reach out to me on...",
  content: [
    {
      id: "1231d3ed",
      type: "social",
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/eddybanks03/",
      icon: "",
    },
    {
      id: "qf312f32",
      type: "social",
      name: "Behance",
      url: "https://www.behance.net/eddybanks",
      icon: "",
    },
    {
      id: "4f23rf24",
      type: "social",
      name: "Instagram",
      url: "https://www.instagram.com/ed_mka/",
      icon: "",
    },
    {
      id: "g53g53r3g",
      type: "email",
      name: "Or just email",
      url: "",
      label: "Email",
    },
  ],
  nextPageLink: {
    label: "You've reached the end of my portfolio. Let's connect soon :)",
    path: "/contact",
  },
};
