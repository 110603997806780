import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { edmkaColors } from "../../ui/colors";

interface ProjectDescriptionProps {
  title: string;
  description: string;
  stack: string[];
  imageLink: string;
  github: string;
  projectLink: string;
}

export const ProjectDescription = ({
  title,
  description,
  stack,
  imageLink,
  github,
  projectLink,
}: ProjectDescriptionProps) => {
  return (
    <CardContainer imageurl={imageLink}>
      <TitleBar>
        <h3>{title}</h3>
        <IconLink href={github} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={["fab", "github"]} />
        </IconLink>
        <IconLink href={projectLink} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={["fas", "external-link-alt"]} />
        </IconLink>
      </TitleBar>
      <Description>{description}</Description>
      <StackList>
        {stack.map((stackItem, idx) => (
          <StackItem key={`stack-item-${idx}`}>{stackItem}</StackItem>
        ))}
      </StackList>
    </CardContainer>
  );
};

interface CardContainerProps {
  imageurl: string;
}

const CardContainer = styled.div<CardContainerProps>`
  display: grid;
  gap: 1em;
  align-content: space-between;
  padding: 1.3em;
  overflow-y: auto;
  min-height: max(250px, 30vh);
  width: max(250px, 30vw);
  scrollbar-width: 0.1rem;
  background-image: linear-gradient(
      ${edmkaColors.black(0.9)},
      ${edmkaColors.black(0.6)}
    ),
    ${(props) => `url(${props.imageurl})`};
  background-color: ${edmkaColors.black(0.5)};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.1rem;
  color: ${edmkaColors.lightGreen()};
  line-height: 150%;
  filter: drop-shadow(0.2em 0.2em 0.2em ${edmkaColors.orange(0.2)});
  border-radius: 1em;
`;

const TitleBar = styled.div`
  display: flex;
  gap: 1em;
  text-transform: uppercase;
  font-weight: 700;
`;

const Description = styled.p`
  font-size: 0.8rem;
  color: ${edmkaColors.pinkish()};
`;

const IconLink = styled.a`
  text-decoration: none;
  color: ${edmkaColors.platinum()};
`;

const StackList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 0.3em;
  height: fit-content;
`;

const StackItem = styled.li`
  width: fit-content;
  height: fit-content;
  padding: 0.1em 0.4em;
  font-size: 0.7rem;
  color: ${edmkaColors.platinum()};
  list-style: none;
`;
