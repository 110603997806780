import { Fragment } from "react";
import styled from "styled-components";
import { resume } from "../mockdata/resume";
import { Content, edmkaColors } from "../styles/SharedStyles";
import { Resume as ResumeTypes } from "../types/Resume";

const resume_link =
  "https://www.dropbox.com/s/83t0pae22i7hdaz/Resume%20-%20Developer.pdf?dl=0";

const Resume = () => {
  const { header, content }: ResumeTypes = resume;
  return (
    <PageContainer id="resume">
      <MainHeader>
        <h1>{header}</h1>
        <ResumeDownload
          href={resume_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download
        </ResumeDownload>
      </MainHeader>
      <ContentContainer>
        {content.map((content) => (
          <Fragment key={content.id}>
            <Header>{content.name}</Header>
            <SectionContainer>
              {content.sections.map((section) => (
                <Section key={section.id}>
                  {section.subsubtitle && (
                    <SubSubTitle>{section.subsubtitle}</SubSubTitle>
                  )}
                  <div>
                    {section.title && <Title>{section.title}</Title>}
                    {section.subtitle && (
                      <SubTitle>{section.subtitle}</SubTitle>
                    )}
                  </div>
                  {section.description && (
                    <Description>
                      {section.description?.title && section.description.title}
                      {": "}
                      {section.description?.content &&
                        section.description.content}
                    </Description>
                  )}
                  {section.list && (
                    <List>
                      {section.list.map((listItem, idx) => (
                        <ListItem key={`${section.id}-${idx}`}>
                          {listItem}
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Section>
              ))}
            </SectionContainer>
          </Fragment>
        ))}
      </ContentContainer>
    </PageContainer>
  );
};

const PageContainer = styled.section`
  display: grid;
  gap: 1em;
  align-content: center;
  justify-items: center;
  min-height: min(90vh, 1000px);
  padding-block-start: min(35%, 5em);
  color: ${edmkaColors.lightGreen()};
`;

const MainHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  filter: drop-shadow(0.2em 0.2em 0.1em ${edmkaColors.darkBrown()});
  gap: 5em;
  h1 {
    font-size: 1.3rem;
  }
`;

const Header = styled.h3`
  writing-mode: vertical-lr;
  text-orientation: upright;
  text-transform: uppercase;
  @media screen and (min-width: 1120px) {
    writing-mode: horizontal-tb;
  }
`;

const ContentContainer = styled(Content)`
  gap: 0.3rem;
  grid-template-columns: max-content auto;
  overflow-y: auto;
  max-width: max(16em, 60%);
  min-height: min(60vh, 40em);
  padding: min(10%, 2em);
  background-color: ${edmkaColors.black(0.5)};
`;

const ResumeDownload = styled.a`
  display: grid;
  width: fit-content;
  text-decoration: none;
  color: ${edmkaColors.pinkish()};
  padding: 0.3rem;
  font-size: 0.8rem;
  border: 1px solid ${edmkaColors.lightGreen(0.7)};
  background-color: ${edmkaColors.darkBrown()};
  :hover,
  :visited {
    text-decoration: none;
  }
`;

const SectionContainer = styled.div`
  display: grid;
  gap: 2rem;
  height: fit-content;
  border-left: 0.2rem solid ${edmkaColors.lightGreen(0.8)};
  padding-left: 1rem;
  margin-bottom: 3rem;
  @media screen and (min-width: 1120px) {
    padding-left: 3rem;
  }
`;

const Section = styled.section`
  display: grid;
  gap: 1rem;
  align-items: flex-start;
`;

const Title = styled.h3`
  text-transform: uppercase;
`;

const SubTitle = styled.h3``;

const SubSubTitle = styled.h5`
  text-transform: uppercase;
  color: ${edmkaColors.lightGrey()};
`;

const Description = styled.p`
  font-style: italic;
`;

const List = styled.ul`
  display: grid;
  gap: 0.5em;
`;

const ListItem = styled.li`
  list-style: none;
`;

export default Resume;
