export const about = {
  header: "My Journey",
  content: [
    [
      "I started my engineering journey as a Computer Engineering college major in South Korea at Chungnam National University. ",
      "I pursued a graduate degree in the US immediately after, at the University of New Mexico (UNM) in Albuquerque, New Mexico. ",
      "While in grad school, I worked part-time developing Ruby on Rails web applications with an agile team of engineers at the UNM Institute of Design and Innovation as a graduate assistant.",
    ],
    [
      "On graduating, I pursued my interest in data engineering and data analysis with the UNM Office of Institutional Analytics as a full-time data analyst. ",
      "I gained over four years of experience in this role. ",
      "Outside of work, I kept up to date with front-end technologies by building a few web and mobile applications. ",
      "I also worked in an agile team from the chingu.io platform on various full-stack web projects. ",
    ],
    [
      "Fast-forward to the present, I am looking for full-time roles in web/software development where I can grow my career in tech while being mentored my more experienced engineers. ",
      "I speak four languages; English (native) and Korean with professional proficiency, Japanese and French, conversationally. ",
      "I love to play the piano, draw quirky and fun art, watch anime and learn new languages (currently adding Spanish to my list). ",
    ],
  ],
  nextPageLink: {
    label: "Move on to my work experience and such",
    path: "/resume",
  },
};
