import styled from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Navbar } from "../components/Navigation";
import About from "./About";
import Landing from "./Landing";
import Projects from "./Projects";
import Resume from "./Resume";
import Contact from "./Contact";

library.add(fab, faGithub, faExternalLinkAlt);

function App() {
  return (
    <Container>
      <Navbar />
      <Landing />
      <About />
      <Resume />
      <Projects />
      <Contact />
    </Container>
  );
}

const Container = styled.div`
  display: grid;
`;

export default App;
