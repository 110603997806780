export const edmkaColors = {
  darkBrown: (opacity: number = 1) => `rgba(56, 0, 0, ${opacity})`,
  green: (opacity: number = 1) => `rgba(133, 172, 145, ${opacity})`,
  lightGrey: (opacity: number = 1) => `rgba(174, 174, 174, ${opacity})`,
  pinkish: (opacity: number = 1) => `rgba(217, 196, 196, ${opacity})`,
  grey: (opacity: number = 1) => `rgba(112, 112, 112, ${opacity})`,
  lightGreen: (opacity: number = 1) => `rgba(231, 251, 233, ${opacity})`,
  orange: (opacity: number = 1) => `rgba(184, 12, 9, ${opacity})`,
  platinum: (opacity: number = 1) => `rgba(229, 231, 230, ${opacity})`,
  black: (opacity: number = 1) => `rgba(0,0,0, ${opacity})`,
  tonerGrey: (opacity: number = 1) => `rgba(183, 181, 179, ${opacity})`,
  white: (opacity: number = 1) => `rgba(255, 255, 255, ${opacity})`,
};
