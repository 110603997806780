import { useState } from "react";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import edmka from "../assets/edmka.svg";
import { device } from "../ui/media-queries";
import { edmkaColors } from "../ui/colors";

export const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const toggleNav = () => setToggle(!toggle);

  return (
    <Container>
      <LogoBar>
        <Brand href="/">
          <Logo src={edmka} alt="Logo" />
        </Brand>
        <MenuToggle>
          {toggle ? (
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => setToggle(!toggle)}
            />
          ) : (
            <FontAwesomeIcon icon={faBars} onClick={toggleNav} />
          )}
        </MenuToggle>
      </LogoBar>
      <NavList toggle={toggle}>
        <NavItem>
          <NavLink href="#about" onClick={toggleNav}>
            About
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#resume" onClick={toggleNav}>
            Resume
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#projects" onClick={toggleNav}>
            Projects
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#contact" onClick={toggleNav}>
            Contact
          </NavLink>
        </NavItem>
      </NavList>
    </Container>
  );
};

const Container = styled.nav`
  display: grid;
  align-content: flex-start;
  text-transform: lowercase;
  position: fixed;
  inset: 0 0 auto 0;
  z-index: 1000;
  background-color: ${edmkaColors.black(0.6)};
  box-shadow: 0.2em 0.8em 1em ${edmkaColors.darkBrown(0.3)};
  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
    padding-inline: clamp(1em, 5vw, 6vw);
  }
`;

const LogoBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  color: ${edmkaColors.lightGreen()};
  @media ${device.laptop} {
    background-color: unset;
    box-shadow: none;
  }
`;

const Brand = styled.a`
  padding-block: 0.3em;
`;

const Logo = styled.img`
  height: clamp(2em, 3em, 4em);
`;

const MenuToggle = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 0.1em;
  width: clamp(1.2em, 1.7em, 3.2em);
  font-size: clamp(1em, 1.5em, 3em);
  background-color: ${edmkaColors.lightGreen(0.2)};
  aspect-ratio: 1;
  @media ${device.laptop} {
    display: none;
  }
`;

const NavList = styled.ul<{ toggle?: boolean }>`
  display: ${(props) => (props.toggle ? "grid" : "none")};
  gap: 1em;
  background-color: ${edmkaColors.black(0.6)};
  backdrop-filter: blur(1rem);
  padding-block: 1em;
  @media ${device.laptop} {
    display: flex;
    justify-content: end;
    background-color: unset;
  }
`;

const NavItem = styled.li`
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: ${edmkaColors.lightGreen()};
`;
