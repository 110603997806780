import styled from "styled-components";
import { Link } from "react-router-dom";

export const edmkaColors = {
  darkBrown: (opacity: number = 1) => `rgba(56, 0, 0, ${opacity})`,
  green: (opacity: number = 1) => `rgba(133, 172, 145, ${opacity})`,
  lightGrey: (opacity: number = 1) => `rgba(174, 174, 174, ${opacity})`,
  pinkish: (opacity: number = 1) => `rgba(217, 196, 196, ${opacity})`,
  grey: (opacity: number = 1) => `rgba(112, 112, 112, ${opacity})`,
  lightGreen: (opacity: number = 1) => `rgba(231, 251, 233, ${opacity})`,
  orange: (opacity: number = 1) => `rgba(184, 12, 9, ${opacity})`,
  platinum: (opacity: number = 1) => `rgba(229, 231, 230, ${opacity})`,
  black: (opacity: number = 1) => `rgba(0,0,0, ${opacity})`,
  tonerGrey: (opacity: number = 1) => `rgba(183, 181, 179, ${opacity})`,
  white: (opacity: number = 1) => `rgba(255, 255, 255, ${opacity})`,
};

export const Container = styled.main`
  display: grid;
  justify-content: center;
`;

export const PageContainer = styled.main`
  display: grid;
  justify-content: flex-start;
  font-size: 1rem;
  margin: 1em 1.5em;
  padding: 1em;
  border: 1px solid ${edmkaColors.lightGreen(0.7)};

  @media (min-width: 768px) {
    margin: 5em;
    padding: 2em 5em;
  }

  @media (min-width: 1000px) {
    font-size: 1.2rem;
  }
`;

export const HeaderGreen = styled.h3`
  color: ${edmkaColors.green()};
  font-weight: 300;
  font-size: 1.3em;
`;

export const HeaderOrange = styled.h3`
  color: ${edmkaColors.tonerGrey()};
  font-weight: 300;
  font-size: 1.3em;
`;

export const Content = styled.div`
  display: grid;
  gap: 1rem;
  align-content: flex-start;
  align-items: flex-start;
  height: 35vh;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
`;

export const NextPageLink = styled(Link)`
  text-decoration: none;
  color: ${edmkaColors.green()};
`;
