import styled from "styled-components";
import { edmkaColors } from "../ui/colors";
import { NextPageLink } from "../ui/NextPageLink";

const Landing = () => {
  return (
    <Container id="landing">
      <Headline>
        <Header>Hi, my name is</Header>
        <HeadlineName>Edwin Agbenyega</HeadlineName>
        <HeadlineContent>
          <p>I design and create web applications and data visualizations.</p>
          <p>
            I enjoy every aspect of full-stack development, and my strengths lie
            in implementing UI designs, efficient front-end state management and
            API integrations, using front-end technologies with clean code.{" "}
          </p>
        </HeadlineContent>
        <NextPageLink
          path="about"
          label="Stick around to learn more about my journey -&gt;"
        />
      </Headline>
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  align-content: flex-end;
  min-height: min(90vh, 1000px);
  padding-block: 2em min(5%, 15em);
  justify-items: center;
`;

const Header = styled.h1`
  font-size: clamp(0.5rem, 1.3rem, 2rem);
  color: ${edmkaColors.green()};
`;

const Headline = styled.div`
  display: grid;
  grid-gap: 1.5em;
  border-radius: 1em;
  max-width: max(16em, 60%);
  padding: min(10%, 2em);
  line-height: 150%;
  background-color: ${edmkaColors.black(0.5)};
  border: 0.1em solid ${edmkaColors.lightGreen(0.4)};
`;

const HeadlineName = styled.h2`
  color: ${edmkaColors.lightGreen()};
  font-size: clamp(2rem, 2.5rem, 3rem);
  text-transform: uppercase;
  line-height: 150%;
`;

const HeadlineContent = styled.p`
  display: grid;
  gap: 1em;
  color: ${edmkaColors.white()};
  font-size: clamp(0.8rem, 1.2rem, 1.6rem);
  line-height: 120%;
`;

export default Landing;
